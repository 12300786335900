
@primaryColor: red;

@import "vendor/bower/bootstrap/less/bootstrap.less";
//@import "../bower/bootstrap/less/normalize.less";
//@import "../bower/bootstrap/less/print.less";
//@import "../bower/bootstrap/less/variables.less";
//@import "../bower/bootstrap/less/scaffolding.less";
//@import "../bower/bootstrap/less/type.less";
//@import "../bower/bootstrap/less/code.less";
//@import "../bower/bootstrap/less/grid.less";
//@import "../bower/bootstrap/less/tables.less";
//@import "../bower/bootstrap/less/forms.less";
//@import "../bower/bootstrap/less/buttons.less";
//@import "../bower/bootstrap/less/utilities.less";
//@import "../bower/bootstrap/less/navbar.less";
//@import "../bower/bootstrap/less/navs.less";
//@import "../bower/bootstrap/less/dropdowns.less";
//@import "../bower/bootstrap/less/labels.less";
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
src: local('Roboto Light'), local('Roboto-Light'), url(/fonts/Roboto-Light.woff2) format('woff2'), url(/fonts/Roboto-Light.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Regular'), local('Roboto-Regular'), url(/fonts/Roboto-Regular.woff2) format('woff2'), url(fonts/Roboto-Regular.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(/fonts/Roboto-Medium.woff2) format('woff2'), url(fonts/Roboto-Medium.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(/fonts/Roboto-Bold.woff2) format('woff2'), url(fonts/Roboto-Bold.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local('Roboto Italic'), local('Roboto-Italic'), url(/fonts/Roboto-Italic.woff2) format('woff2'), url(fonts/Roboto-Italic.woff) format('woff');
}

body {
  font-family: Roboto, sans-serif;
  background: white url(/images/palagruza/luftbild-palagruza.jpg) no-repeat fixed center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@-webkit-keyframes active{
  0%{
    -webkit-transform:scale(.1);
    opacity:1;
  }
  70%{
    -webkit-transform:scale(2.5);
    opacity:0;
  }
  100%{
    opacity:0;
  }
}

@keyframes active{
  0%{
    transform:scale(.1);
    opacity:1;
  }
  70%{
    transform:scale(2.5);
    opacity:0;
  }
  100%{
    opacity:0;
  }
}

.live{
  content:"";
  display:inline-block;
  background-color:red;
  height:1em;
  width:1em;
  border-radius:50%;
  -webkit-transform:translateX(-50%) translateY(-50%);
  -moz-transform:translateX(-50%) translateY(-50%);
  position:relative;
  top:15px;
  left:14px;
}
.live:before{
  display:inline-block;
  content:"";
  height:1em;
  width:1em;
  background-color:transparent;
  border-radius:50%;
  box-shadow:0 0 2px 2px red;
  -webkit-animation:active 2s infinite linear;
  animation:active 2s infinite linear;
  position:absolute;
  top:0;
  left:0;
}
.offline{
  display:inline-block;
  background-color:grey;
  height:1em;
  width:1em;
  border-radius:50%;
  position:relative;
  -webkit-transform:translateX(-50%) translateY(-50%);
  -moz-transform:translateX(-50%) translateY(-50%);
  top:15px;
  left:14px;
}

.status-offline{
  color:grey;
}